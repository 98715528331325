.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.App-logo {
  height: 400px;
  margin-top: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.hero-section {
  background-color: #f9f9f9;
  padding: 50px 20px;
}

.hero-section h1 {
  font-size: 2.5em;
  color: #ff6347;
}

.hero-section p {
  font-size: 1.2em;
  color: #555;
}

.hero-section button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hero-section button:hover {
  background-color: #e55342;
}

.about-section,
.courses-section,
.testimonials-section,
.contact-section {
  padding: 50px 20px;
}

.about-section p,
.courses-section p,
.testimonials-section p,
.contact-section p {
  font-size: 1em;
  color: #555;
}

.carousel {
  display: flex;
  overflow: auto;
  justify-content: center;
}

.carousel p {
  margin: 0 15px;
  font-style: italic;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form label {
  margin-bottom: 10px;
}

form input,
form textarea {
  padding: 10px;
  width: 300px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

form button:hover {
  background-color: #e55342;
}

footer {
  background-color: #333;
  color: white;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}

footer p {
  margin: 0;
}
